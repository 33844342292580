html,
body {
    height: 100%;
}

.navbar-expand-xl .navbar-collapse,
.navbar-expand-lg .navbar-collapse,
.navbar-expand-sm .navbar-collapse,
.navbar-expand-md .navbar-collapse {
    justify-content: flex-end;
}

#content {
    .call {
        background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.5rem;
        margin-left: 0.8rem;

        a {
            

            &:hover {
                text-decoration: none;
            }

            &:focus {
                outline: none;
            }

        }
    }
}
.fixed-nav {
    position: fixed;
    top: 0;
    width: 100%;
} 

nav {
    background-color: rgb(0,0,0);
    width: 100vw;
    margin: 0;
    top: 0;
    overflow: hidden;
    z-index: 99;
    position: fixed;

    navbar-toggler-icon:focus {
        outline: none;
    }

    button:focus {
        outline: none;
    }

    .navbar-light .navbar-nav .nav-link.active {
        color: white;
    }

    .navbar-brand {
        font-size: 1.6rem;
    }

    .navbar-nav  {
        font-size: 1.2rem;
        text-align: center;
    }

    .navbar-light .navbar-brand:hover {
        color: rgba(255,255,255,0.7);
    }

    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(255,255,255,0.7);
    }

    .navbar-light .navbar-brand,
    .navbar-light .navbar-nav .nav-link {
        color: white;
    }

    .navbar-light .navbar-brand:focus {
        color: white;
    }

    .navbar-collapse .collapse .show .navbar-nav {
        display: inline-block;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .nav-link {
        display: inline-block;
        width: fit-content;
        margin: 0 auto;
    }

    .nav-link:focus {
        outline: none;
    }

    .navbar-light .navbar-toggler {
        border-color: rgba(255,255,255,0.5);
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}

@media  (max-width: 760px) {
    #content {
        .call {
            margin-left: 0;
            margin-right: 14px;
        }
    }
}

@media  (max-width: 425px) {
    #content {
        .call {
            margin-right: 2px;
        }
    }
}

@media  (max-width: 375px) {
    #content {
        .call {
            font-size: 1.3rem;
        }
    }
}

@media  (max-width: 360px) {
    #content {
        .call {
            font-size: 1.2rem;
        }
    }
}

@media  (max-width: 355px) {
    #content {
        .call {
            font-size: 1rem;
        }
    }
}

