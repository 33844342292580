html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.counters-container {
    background-image: url('images/first-page-slide1.jpg');
    height: 70vh; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 2.5rem;
    margin: 0.8rem 0 0.8rem 0;
    text-align: center;
    color: white;
    font-family: "Montserrat", Sans-serif;
    letter-spacing: 1.08px;
    font-weight: 400;

        div {
            background-color: rgba(0, 0, 0, 0.65);
            margin: 1rem;
            width: 22%;
            height: 10rem;
            border-radius: 5px;
            padding: 0.5rem;

            span {
                font-size: 2.5rem;
                background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

        h2 {
            font-size: 1.5rem;
            padding-top: 0.5rem;
        }
    }
}

@media  (max-width: 1050px) {
    .counters-container {  
        div {
            height: 12rem;
            margin: 0.5rem;

            span {
                font-size: 2rem;
            }
        }
    }
}

@media  (max-width: 820px) {
    .counters-container {
        background-attachment: unset;
    
        div {
            width: 40%;
            height: 10rem;
        }
    }
}

@media  (max-width: 650px) {
    .counters-container {
        justify-content: center;
    }
}

@media  (max-width: 560px) {
    .counters-container {
        div {
            span {
                font-size: 1.8rem;
            }

            h2 {
                font-size: 1.2rem;
            }
        }
    }
}

@media  (max-width: 465px) {
    .counters-container {
        div {
            width: 51%;
            height: 8rem;
            margin: 0;
            padding: 0;

            h2 {
                margin-bottom: 0;
                padding-top: 0;
            }
        }
    }
}

@media  (max-width: 375px) {
    .counters-container {
        div {
            width: 55%;
            height: 8rem;
        }
    }
}

@media  (max-width: 360px) {
    .counters-container {
        div {
            width: 60%;
            height: 7.5rem;
        }
    }
}

@media  (max-width: 320px) {
    .counters-container {
        div {
            width: 70%;
            height: 7rem;
        }
    }
}