html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.main-container {
    padding-top: 3.8rem;
    color: white;

    h2 {
        color: black;
        font-size: 2.3rem;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    .our-services-container {
        .service1 {
            background: url('images/our-services-door.jpg');

            .badges {
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
                font-size: 2rem;
                padding: 0 25px 0 25px;
                top: 2rem;
            }

            .service1-text-container {
                width: 45%;
                background-color: rgba(0,0,0,.65);
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                padding: 10px;
                left: 3rem;
                text-indent: 15px;
                text-align: left;
            }
        }

        .service2 {
            background: url('images/our-services-cabinets.jpg') no-repeat center;

            .service2-text-container {
                width: 45%;
                background-color: rgba(0,0,0,.65);
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                padding: 10px;
                float: right;
                right: 3rem;
                text-indent: 15px;
                text-align: left;
            }
        }

        .service3 {
            background: url('images/our-services-basement-renovation.jpeg') no-repeat center;
        
            .service3-text-container {
                width: 45%;
                background-color: rgba(0,0,0,.65);
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                padding: 10px;
                right: 3rem;
                float: right;
                text-indent: 15px;
                text-align: left;

                .contact-us {
                    background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 1.5rem;
                    margin-left: 0.8rem;
                }

                a { 
                    &:hover {
                        text-decoration: none;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .service4 {
            background: url('images/our-services-general-contractors.jpg') no-repeat center;
        
            .service4-text-container {
                width: 55%;
                background-color: rgba(0,0,0,.65);
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                padding: 10px;
                left: 3rem;
                text-indent: 15px;
                text-align: left;
            }
        }

        .service5 {
            background: url('https://res.cloudinary.com/benwillcabinets/image/upload/inspiration_photos/kitchen/185aa55755e7d53b61570216167a2b1f-xxlarge_kzcdks.jpg') no-repeat center;
        
            .service5-text-container {
                width: 55%;
                background-color: rgba(0,0,0,.65);
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                padding: 10px;
                float: right;
                right: 3rem;
                text-indent: 15px;
                text-align: left;

                .contact-us {
                    background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 1.5rem;
                    margin-left: 0.8rem;
                }

                a { 
                    &:hover {
                        text-decoration: none;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 {
            margin-bottom: 1rem;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 92vh;

            &:last-child {
                margin-bottom: 0;
            }

            .service1-text-container,
            .service2-text-container,
            .service3-text-container,
            .service4-text-container,
            .service5-text-container {
                font-size: 1.5rem;
            }
                
                .different {
                    font-weight: bold;
                }
            

            h3 {
                background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align: left;
                text-indent: 8px;
            }

            .service-icons {
                margin-right: .9rem;
                background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }         
    }

    h3, h2 {
        text-align: center;
    }

}

@media  (max-width: 968px) {
    .display-none {
        display: none;
    }

    .main-container .our-services-container {
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 { 
            .service1-text-container,
            .service2-text-container,
            .service3-text-container,
            .service4-text-container,
            .service5-text-container {
                .different {
                    font-weight: normal;
                } 
            }
        }
    }
}

@media  (max-width: 768px) {
    .main-container .our-services-container {
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 { 
            background-size: cover;

            .service1-text-container,
            .service2-text-container,
            .service3-text-container {
                width: 50%;
                
                .different {
                    font-weight: normal;
                }
            }

            .service4-text-container,
            .service5-text-container {
                width: 75%;
            }
        }
    }
}

@media  (max-width: 500px) {
    .main-container .our-services-container {
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 { 
            .service1-text-container,
            .service2-text-container,
            .service3-text-container {
                font-size: 1.35rem;
                width: 60%;
            }

            .service4-text-container {
                width: 92%;
                float: left;
                left: 0.2em;
                font-size: 1.35rem;
            }
        }
    }
}

@media  (max-width: 375px) {
    .main-container .our-services-container {
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 { 
            .service1-text-container,
            .service2-text-container,
            .service3-text-container {
                width: 65%;
            }

            .service5-text-container {
                width: 80%;
            }
        }
    }
}

@media  (max-width: 325px) {
    .main-container .our-services-container {
        .service1,
        .service2,
        .service3,
        .service4,
        .service5 { 
            .service1-text-container,
            .service2-text-container,
            .service3-text-container,
            .service5-text-container {
                width: 85%;
            }
        }
    }
}