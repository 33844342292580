.warranty-container {
    margin-top: 99px;
    min-height: calc(100vh - 86px);
    color: #7A7A7A;
    padding: 10px 20px 20px 20px;
    text-indent: 20px;
    font-size: 11pt;
    background-color: #F4F6F7;

    h1 {
        text-align: center;
        font-size: 1.8rem;
        padding-top: 30px;
        margin-bottom: 40px;
        color: #212529;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    h3 {
        color: #3a3a3a;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.3rem;
    }

    ol:first-child {
        padding-inline-start: 0;
    }

    ol,
    ul {
        text-indent: 0;
        padding-inline-start: 15px;
    }
}