html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

#breadcrumb {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 98;
}

.breadcrumb {
    margin-bottom: 0 !important;
    background-color: white !important;
    border-radius: unset !important;
    border-bottom: 1px solid #e9ecef;
    font-weight: bold;

    .breadcrumb-item {
        a {
            background: -webkit-gradient(linear,left top,left bottom,from(#f6e6b4),to(#ed9017));
            background: linear-gradient(180deg,#f6e6b4 0,#ed9017);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.ScrollUpButton__Container {
    position: fixed;
    right: -100px;
    bottom: 50px;
    transition: right 0.5s;
    cursor: pointer;
    // background-color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
    padding: 10px;
    z-index: 2;
    border-radius: 10px;
    background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
    opacity: 0.5;

    &:hover {
        opacity: unset;
    }
}
  
  .ScrollUpButton__Toggled {
    right: 10px;
}


.breadcrumb-item {
    color:#ed9017;

    a {
        color:#ed9017;
    }
}

#breadcrumb nav {
    //overflow: unset !important;
    //position: absolute;
    //top: 56px;
    //z-index: unset !important;
}

.breadcrumb {
    margin-bottom: 0 !important;
    border-radius: unset !important;
    border-bottom: 1px solid #e9ecef;
    font-weight: bold;

    .breadcrumb-item {
        a {
            background: -webkit-gradient(linear,left top,left bottom,from(#f6e6b4),to(#ed9017));
            background: linear-gradient(180deg,#f6e6b4 0,#ed9017);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}