html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.map-container {
    padding-top: 3.8rem;

    h2 {
        font-size: 2.3rem;
        text-align: center;
        margin-bottom: 2.2rem;
    }
}