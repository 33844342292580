.inspiration-photos-container {
    background-color: #F4F6F7;
    min-height: 100vh;
    margin-top: 99px;
    margin-bottom: 2rem;

    h2 {
        text-align: center;
        padding-top: 30px;
        margin-bottom: 40px;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    p {
        color: #7A7A7A;
        text-align: left;
        text-indent: 15px;
        padding: 10px  15px;
        font-size: 1.2rem;
        margin-bottom: 0;

        span  {
            color: #3a3a3a;
            font-style: italic;
        }
    }

    .container {
        margin-top: 20px;
        max-width: 1270px;

        .card-body {
            .card-text {
                color: #8c98a4;
                font-weight: bold;
            }
        }
    }

    img {
        height: auto;
        min-height: 20rem;
        max-height: 20rem;
        min-width: 20rem;
        max-width: 20rem;
        object-fit: cover;
    }

    a {
        color: black;
        margin: 0 auto;
        margin-bottom: 2rem;

        &:hover {
            text-decoration: none;
        }

        .card {
            box-shadow: 10px 10px 5px 0 #ddd;
            border: 1px solid #e8e8e8;
            align-items: center;
    
            &:hover {
                box-shadow: 10px 10px 5px 0 #c8def0;
                cursor: pointer;
            }
        }

        .card-body {
            height: fit-content;
            text-indent: 15px;
            text-align: left;
            white-space: pre-line;
        }
    }
}

@media (max-width: 1239px) {
    .inspiration-photos-container {
        .container {
            max-width: 960px;
        }
    }
}

@media (max-width: 919px) {
    .inspiration-photos-container {

        img {
            min-height: 25rem;
            max-height: 25rem;
            min-width: 25rem;
            max-width: 25rem;
        }

        .container {
            .card {
                width: 25rem !important;
            }
        }

        a {
            .card-body {
                height: fit-content;
            }
        }
    }
}