.faq-container {
    margin-top: 99px;
    min-height: calc(100vh - 86px);
    background-color: #F4F6F7;
    padding-top: 10px;

    p {
        color: #3a3a3a;
    }

    p, 
    h1 {
        text-align: center;
    }

    h1 {
        font-size: 1.8rem;
        padding-top: 30px;
        margin-bottom: 40px;
        color: #212529;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }
    
    .card-header{
        color: #3a3a3a;
        font-size: 1.2rem;

        &:hover {
            cursor: pointer;
        }
    }

    .card-body {
        font-size: 1rem;
        text-indent: 20px;
        color: #7A7A7A;
    }
}