html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

#about-us {
    padding: 3.8rem 0.8rem 0.8rem 0.8rem;

    .about-us-text {
        .card {
            max-width: 60%;
            margin: 0 auto;
            border: none;
        }

        p {
            font-size: 1.5rem;
            text-indent: 15px;
            text-align: justify;

            &:last-child {
                text-align: justify;
                text-transform: none;
            }
        }
    }

    h2 {
        text-align: center;
        font-size: 2.3rem;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    .about-us-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0.8rem;

        .column {
            width: 40%;
            margin: 0.8rem;

            h3 {
                text-align: center;
            }

            p {
                color: black;
                font-size: 1.25rem;
                text-align: justify;
                text-indent: 15px;
                text-align: justify;
            }

            .fa, .fas, .far {
                display: inline-block;
                margin-left: 50%;
                transform: translateX(-50%);
                font-size: 2.5rem;
                color: #e74e37;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

@media  (max-width: 768px) {
    #about-us {
        .about-us-text {
            .card {
                max-width: 70%;
            }

            p:last-child {
                font-size: 1.2rem;
                margin-bottom: 0;
            }
        }
        .about-us-container {
            .column {
                .far, .fas, .fa {
                    font-size: 1.8rem;
                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: normal;
                }

                p {
                    display: none;
                }
            }
        }
    }  
}

@media  (max-width: 425px) {
    #about-us {
        .about-us-text {
            .card {
                max-width: 80%;
            } 
        }
        
    }  
}

@media  (max-width: 375px) {
    #about-us {
        .about-us-text {
            .card {
                max-width: 90%;
            } 
        }
        
    }  
}

@media  (max-width: 320px) {
    #about-us {
        .about-us-text {
            .card {
                max-width: 100%;
            } 
        }
        
    }  
}