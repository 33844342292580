html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

#breadcrumb nav {
    overflow: unset !important;
    position: absolute;
    top: 56px;
    z-index: unset !important;
}

.cost-estimator-container {
    min-height: calc(100vh - 87px);
    text-align: center;
    background: #F4F6F7;
    margin-top: 99px;

    .smaller-text {
        font-size: 8pt;
        margin-top: 0 !important;
        margin-bottom: 0;
    }

    #contact-checkbox {
        margin: 0 auto;
        width: fit-content;
        padding: 10px 0;

        button {
            border-color: transparent;
            background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.2rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: -moz-fit-content;
            width: fit-content;
            margin: 0 auto;

            &:focus {
                box-shadow: none;
            }
        
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
                cursor: pointer;
            }
        }
    }

    .show-success {
        position: absolute;
        top: 135px;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

        p {
            text-align: center;
            margin-bottom: 0;
        }
    }

    #change-category-link-container {
        text-align: left;
         
        button {
            color: rgba(0, 0, 0, 0.5);

            &:hover {
                text-decoration: none;
                color: #ed9017;
            }
        }
    }

    .note {
        font-size: 11pt;
        margin-bottom: 40px;
        color: #500505;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        justify-content: center; 
        text-align: left;
        margin-bottom: 25px;

        .column1, 
        .column2 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .text {
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .column1 {
            margin-right: 6em;
        }
    }

    #button-container {
        padding-top: 20px;

        #button {
            border-color: transparent;
            background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.5rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: fit-content;
            margin-left: 14px;
            
            &:focus {
                box-shadow: none;
            }
        
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
                cursor: pointer;
            }
        }
    }

    h2 {
        padding-top: 30px;
        margin-bottom: 40px;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    h6 {
        color: #8c98a4;
    }

    h4 {
        padding-top: 3.8rem;
    }

    h3 {
        background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .svg-container {
        margin-top: 65px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;

        #bathroom,
        #kitchen,
        #paint,
        #floor,
        #basement,
        #cabinet,
        #counter,
        #door {
            transition: all .3s ease-in-out;
            text-align: center;
            background: #fff;
            padding: 10px;
            -webkit-box-shadow: 5px 5px 11px rgba(255,197,135,1);
            -moz-box-shadow: 5px 5px 11px rgba(255,197,135,1);
            box-shadow: 5px 5px 11px rgba(255,197,135,1);
            margin: 10px 5px;

            path, circle {
                stroke: #ed9017;
                stroke-width: 5px;
            }
            
            &:hover {
                -webkit-box-shadow: 18px 18px 15px rgba(255,197,135,1);
                -moz-box-shadow: 18px 18px 15px rgba(255,197,135,1);
                box-shadow: 18px 18px 15px rgba(255,197,135,1);
                transform: scale(1.1) translateY(-10px);
                cursor: pointer;
            }

            p {
                margin-top: 1rem;
                color: #8c98a4;
                font-weight: bold;
            }
        }

        #new-kitchen,
        #kitchen-refacing,
        #backsplash {
            transition: all .3s ease-in-out;
            text-align: center;
            background: #fff;
            padding: 10px;
            -webkit-box-shadow: 5px 5px 11px rgba(255,197,135,1);
            -moz-box-shadow: 5px 5px 11px rgba(255,197,135,1);
            box-shadow: 5px 5px 11px rgba(255,197,135,1);
            margin: 10px 5px;

            path, circle {
                stroke: #ed9017;
                //stroke-width: 5px;
            }
            
            &:hover {
                -webkit-box-shadow: 18px 18px 15px rgba(255,197,135,1);
                -moz-box-shadow: 18px 18px 15px rgba(255,197,135,1);
                box-shadow: 18px 18px 15px rgba(255,197,135,1);
                transform: scale(1.1) translateY(-10px);
                cursor: pointer;
            }

            p {
                margin-top: 1rem;
                color: #8c98a4;
                font-weight: bold;
            }
        }
    }

    #total-area {
        padding: 10px;
        margin: 10px 5px;

        path, circle {
            stroke: #ed9017;
            stroke-width: 5px;
        }
    }


    form {
        .show-my-estimate-button {
            margin-bottom: 15px;
        }

        .form-group {
            width: fit-content;
            margin-bottom: 15px;

            label {
            margin-bottom: 0;
            }
        }

        button {
            border-color: transparent;
            background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.2rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: -moz-fit-content;
            width: fit-content;
            margin: 0 auto;

            &:focus {
                box-shadow: none;
            }
        
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
                cursor: pointer;
            }
        }
    }

    #button {
        border-color: transparent;
        background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
        padding: 0.5rem;
        color: black;
        font-size: 1.1rem;
        border-radius: 7px;
        display: block;
        transition: all .3s ease-in-out;
        width: fit-content;
        margin: 0 auto;
        
        &:focus {
            box-shadow: none;
        }
    
        &:hover {
            color: black;
            text-decoration: none;
            box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
            transform: scale(1.1) translateY(-10px);
            cursor: pointer;
        }
    }

    .contactform-container {
        padding-top: 0;
    }

    #disclaimer {
        color: #8c98a4;
        border-bottom: 1px dashed #97a4af;

        &:hover {
            border-color: #0052ea;
            color: #0052ea;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.popover-body {
    color: #8c98a4;
}

.pre-estimate-form {
    padding-top: 15px;
    margin-bottom: 15px;

    form {
        margin: 0  auto;
        text-align: center;
        width: fit-content;

        .form-group {
            margin: 0 auto;
            margin-bottom: 5px;
            text-align: center;
        }

        .pre {
            text-align: left;
            margin-top: 10px;
        }
    }

    #g-recaptcha {
        width: 200px;
        height: 74px;
            iframe {
            -webkit-transform: scale(.67);
            transform: scale(.67);
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
        }
    }
}

@media  (max-width: 424px) {
    .cost-estimator-container {
        .input-container {
            flex-direction: column;
            margin: 10px;
            margin-bottom: 5px;

            .column1, 
            .column2 {
                align-items: center;
            }

            .column1 {
                margin-right: 0;
            }
        }

        form {
            .form-group {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 15px;
            }
        }

        h2 {
            margin-bottom: 30px;
        }
    }
}