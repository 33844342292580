html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.container-first-page {

    img {
        filter: brightness(80%);
        height: 100vh;
        object-fit: cover;
    }

    .banner-container {
        color: white;
        position: absolute;
        z-index: 5;
        text-align: center;
        display: block;
        top: 35rem;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 10px;

        h2 {
            font-size: 1.8rem;
            text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
        }
    
        #banner1 {
            padding-bottom: 10px;
        }
    }

    .carousel-item {

        .d-block {
            max-height: 100%;
        }
    }

    .first-page-block-with-text {
        color: white;
        display: inline-block;
        width: fit-content;
        position: absolute;
        right: 0;
        bottom: 0;
        padding-top: 15px;
        left: 0;
        margin: 0 auto;
        top: 0;
        text-align: center;

        &:first-child div {
            display: flex;
            flex-direction: column;
            padding-top: 50px;
            justify-content: center;
        }

        .container {
            position: relative;
            top: 35px;
        }

        &:first-of-type .button {
            margin-bottom: 0.5em;
        }
        
        .button {
            border-color: transparent;
            background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.5rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: fit-content;
            margin: 0 auto;
            
            &:focus {
                box-shadow: none;
            }
    
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
            }
        }

        h1, .header {
            font-size: 3.5rem;
            color: white;
            margin-bottom: 7%;
            text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
            
        }

        h2 {
            font-size: 3rem;
            margin-bottom: 10%;
            text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
        }
    }
}

@media  (max-width: 1440px) {
    .container-first-page {
        .banner-container {
            top: 27rem;
        }
    }
}

@media  (max-width: 1024px) {
    .container-first-page {
        .banner-container {
            top: 31rem;
        }
    }
}

@media  (max-width: 768px) and (max-height: 1024px) {
    .container-first-page {
        .banner-container {
            top: 39rem;
        }
    }
}

@media  (max-width: 768px) {
    .container-first-page {
        .banner-container {
            top: 28rem;
        }
    }
}

@media  (max-width: 655px) {   
    .container-first-page {     
        .first-page-block-with-text {
            h1, .header {
                font-size: 3.2rem;
            }
        }
    }  
}

@media  (max-width: 606px) {   
    .container-first-page {     
        .first-page-block-with-text {
            h1, .header {
                font-size: 3rem;
            }

            h2 {
                font-size: 2.5rem;
            }
        }
    }  
}

@media  (max-width: 425px) {
    .container-first-page {
        .banner-container {
            h2 {
                font-size: 1.7rem;
            }
        }
    }  
}

@media  (max-width: 414px) {
    .container-first-page {
        .banner-container {
            top: 30rem;
        }
    }
}

@media  (max-width: 380px) {
    .container-first-page {
        .banner-container {
            top: 27rem;
        }
    }
}

@media  (max-width: 375px) {
    .container-first-page {
        .banner-container {
            top: 30rem;
        }

        .first-page-block-with-text {
            h1, .header {
                font-size: 2.5rem;
            }

            h2 {
                font-size: 2.3rem;
            }
        }
    }
}

@media  (max-width: 360px) {
    .container-first-page {
        .banner-container {
            top: 25rem;
        }
    }
}

@media  (max-width: 340px) {
    .container-first-page {
        .banner-container {
            top: 27rem;
        }
    }
}

@media screen and (max-width: 320px) {
    .container-first-page {
        .banner-container {
            top: 22rem;

            h2 {
                font-size: 1.5rem;
            }
        }
        
        .first-page-block-with-text {
            h3 {
                font-size: 1.4rem;
            }

            p {
                font-size: 2.5rem;
            }

            .button {
                padding: 0.4rem;
                font-size: 1.2rem;
            }
        }
    }  
}

