html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.testimonials-container {
    padding: 3.8rem 0.8rem 0.8rem 0.8rem;

    .slick-prev:before, .slick-next:before {
        font-size: 1.8rem;
    }

    h2 {
        text-align: center;
        font-size: 2.3rem;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    .each-review {
        padding:  1.1rem 1.1rem 0 1.1rem;
        margin-top: 2rem;
        transform: translateY(-5%);
        margin-left: 0.6rem;
        background-color: #f5f5f5;
        height: 16rem;
        width: 97% !important;
        font-size: 1.1rem;
        border-radius: 10px;

        p:first-of-type {
            font-style: italic;
            text-align: justify;
            text-indent: 1.6rem;
        }

        p:nth-of-type(2) {
            text-align: right; 
            color: #e74e37;
        }

        p {
            margin-bottom: 0;
        }

        .quote {
            color: #e74e37;
            font-size: 2rem;
            margin-right: 0.5rem;
        }

        .fa  {
            text-align: center;
            color: #f1c40f;
            height: auto;
            width: auto;
            font-size: 1.6rem;
            margin-bottom: 0.8rem;
        }
    }

    .slick-prev:before, .slick-next:before {
        display: none;
    }

    .slick-next,
    .slick-prev {
        right: 0;
    }
}

@media  (max-width: 1440px) {
    .testimonials-container {  
        .each-review {
            height: 20rem;
        }
    }
}

@media  (max-width: 940px) {
    .testimonials-container {  
        .each-review {
            height: 22rem;
        }
    }
}

@media  (max-width: 800px) {
    .testimonials-container {  
        .each-review {
            height: 24rem;
        }
    }
}

@media  (max-width: 768px) {
    .testimonials-container {  
        .each-review {
            height: 22rem;
        }
    }
}

@media  (max-width: 556px) {
    .testimonials-container {  
        .each-review {
            height: 20rem;
        }
    }
}

@media  (max-width: 480px) {
    .testimonials-container {  
        .each-review {
            height: 24rem;
        }
    }
}

@media  (max-width: 360px) {
    .testimonials-container {  
        .each-review {
            height: 27rem;
        }
    }
}