html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.contactform-container {
    padding-top: 3.8rem;

    h2 {
        text-align: center;
        font-size: 2.3rem;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
            margin-bottom: 2.2rem;
        }
    }

    .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        font-size: 1.2rem;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        color: white;
        background: url(images/contact-background.jpg) no-repeat center;
        background-size: cover;

        .column2 .contact-button {
            margin-left: 50%;
            transform: translateX(-50%);
            border-color: transparent;
            background: -webkit-gradient(linear,left top,left bottom,from(#f6e6b4),to(#ed9017));
            background: -webkit-linear-gradient(top,#f6e6b4,#ed9017);
            background: linear-gradient(180deg,#f6e6b4 0,#ed9017);
            color: black;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .column1,
        .column2 {
            background-color: rgba(0, 0, 0, 0.65);
            border-radius: 10px;
            padding: 0.8rem;
            width: 21rem;
            text-align: center;
            position: relative;

            textarea {
                background-image: none;
            }

            .show-success {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);

                p {
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }

        h3 {
            margin-bottom: 1.6rem;
        }

        p {
            text-align: left;
        }

        a  {
            color: white;
            transition: all 200ms ease-in; 
            width: fit-content;
            display: inline-block;
            margin-right: 0.8rem;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                text-decoration: none;
            }

            &:hover .contacts{
                transition: all 200ms ease-in;
                transform: scale(1.5);
            }
        }

        .contacts {
            display: inline-block;
            margin-right: 0.8rem;
            font-size: 1.5rem;
            color: #e74e37;
        }
    }

    .follow-us-container {
        display: inline-block;
        text-align: center; 
        margin-top: 50%;
        transform: translateY(-50%);

        p {
            text-align: center;
        }

        #follow {
            background: linear-gradient(to bottom, #f6e6b4 0%, #ed9017 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
        }

        .fa-instagram {
            color: #ec5757;
            font-size: 1.8rem;
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .fa-facebook-square {
            color: #4267b2;
            font-size: 1.8rem;
    
            &:hover {
                opacity: 0.8;
            }
        }
    }

    #g-recaptcha {
        margin: 5px 0;
        
        iframe {
            -webkit-transform: scale(0.9);
            -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
            -o-transform: scale(0.9);
            transform: scale(0.9);
            -webkit-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            -o-transform-origin: 0 0;
            transform-origin: 0 0;
        }
    }

    #upload_widget {
        i {
            margin-right: 10px;
        }

        border-color: transparent;
        background: -webkit-gradient(linear,left top,left bottom,from(#f6e6b4),to(#ed9017));
        background: -webkit-linear-gradient(top,#f6e6b4,#ed9017);
        background: linear-gradient(180deg,#f6e6b4 0,#ed9017);
        color: black;
        margin-left: 50%;
        transform: translateX(-50%);
        width: max-content;
        line-height: 14px;
        padding: 5px 10px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .form-group {
        width: unset;
    }

    .cost-estimator-part {
        margin-top: 3.2em;

        h2 {
            padding-top: 0;
        }
    }
}

@media  (max-width: 760px) {
    .contactform-container .flex-container {
        .column1 {
            margin-bottom: 0.8rem;
        }

    }
}