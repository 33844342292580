html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.main-container {
    padding-top: 3.8rem;

    h2:after {
        margin-bottom: 2.2rem;
    }

    .our-process-container {
        background-image: url('images/first-page-slide3.jpg');
        height: 91vh; 
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .flex-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            justify-items: center;
            height: 100%;
            flex-wrap: wrap;
            padding: 0.8rem;
            font-size: 1.3rem;
        
            p {
                color: white;
                width: 40%;
                margin: 0.8rem;
                background-color: rgba(0, 0, 0, 0.65);
                border-radius: 10px;
                padding: 0.8rem;
                font-size: 1.5rem;
                text-indent: 15px;
                text-align: left;
            }
        }
    }
}

@media  (max-width: 768px) {
    .main-container {
        .our-process-container {
            background-attachment: unset;
    
            .flex-container {
                p {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media  (max-width: 550px) {
    .main-container {
        .our-process-container {
            .flex-container {
                p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media  (max-width: 415px) {
    .main-container {
        .our-process-container {
            .flex-container {
                p {
                    width: 43%;
                }
            }
        }
    }
}

@media  (max-width: 375px) {
    .main-container {
        .our-process-container {
            .flex-container {
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media  (max-width: 342px) {
    .main-container {
        .our-process-container {
            .flex-container {
                p {
                    font-size: 1rem;
                    width: 40%;
                }
            }
        }
    }
}

@media  (max-width: 341px) {
    .main-container {
        .our-process-container {
            .flex-container {
                p {
                    font-size: 0.8rem;
                    width: 42%;
                }
            }
        }
    }
}

