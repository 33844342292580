html,
body {
    height: 100%;
} 

html {
    font-size: 14px;
}

#breadcrumb nav {
    overflow: unset !important;
    position: absolute;
    top: 56px;
    z-index: unset !important;
}

.project-container  {
    margin-top: 110px;

    div {
        margin-top: 15px;
        margin-bottom: 15px;

        img {
            object-fit: cover;
        }

        #button {
            border-color: transparent;
            background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.5rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: fit-content;
            margin: 0 auto;
            
            &:focus {
                box-shadow: none;
            }
        
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
            }
        }
    }
}

.react-images__header .react-images__header--isModal .css-9xjzrb .css-1ycyyax {
    top: 20px !important;
}

.react-images__header .react-images__header--isModal .css-h67k8 .css-1ycyyax {
    top: 20px !important;
}

.css-h67k8 {
    top: 20px !important;
}

.css-m6j0xf {
    top: 20px !important;
}

.css-9xjzrb {
    top: 40px !important;
}

.css-xfk84m {
    position: fixed !important;
}

.css-1h82jk3 { 
    position: fixed !important;
}