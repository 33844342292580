html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

.footer-container {
    background-color: #272727;
    text-align: center;
    color: #888888;
    padding: 1rem;
    font-family: "Montserrat", Sans-serif;
    letter-spacing: 1.08px;
    font-weight: 400;

        #copyright {
            margin-bottom: 0;
        }

        #follow {
            text-align: center;

            a {
                width: fit-content;
                display: inline-block;
                margin-right: 0.8rem;
    
                &:last-child {
                    margin-right: 0;
                }
            }

            .fa-instagram {
                color: #ec5757;
                font-size: 1.8rem;
                margin-left: 0.8rem;
        
                &:hover {
                    opacity: 0.8;
                }
            }
        
            .fa-facebook-square {
                color: #4267b2;
                font-size: 1.8rem;
        
                &:hover {
                    opacity: 0.8;
                }
            }
        }
}

@media  (max-width: 500px) {
    .footer-container {
        #copyright {
            font-size: 0.75rem;
        }
    }
}

@media  (max-width: 380px) {
    .footer-container {
        #copyright {
            font-size: 0.7rem;
        }
    }
}

@media  (max-width: 360px) {
    .footer-container {
        #copyright {
            font-size: 0.65rem;
        }
    }
}

@media  (max-width: 340px) {
    .footer-container {
        #copyright {
            font-size: 0.63rem;
        }
    }
}

@media  (max-width: 335px) {
    .footer-container {
        #copyright {
            font-size: 0.58rem;
        }
    }
}