html,
body {
    height: 100%;
} 

html {
    font-size: 14px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0.8rem;
}

.swiper-container {
    width: 100%;
    padding-top: 3.8rem;
    padding-bottom: 3.8rem;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 600px;
    height: 450px;
}

#gallery {
    padding-top: 3.8rem;
    width: 70%;
    margin: 0 auto;

    .before-after-button-container {
        margin-top: 1em;
    }

    img {
        object-fit: contain;
    }

    h2 {
        color: black;
        text-align: center;
        font-size: 2.3rem;

        &:after {
            background: none repeat scroll 0 0 #e74e37;
            content: "";
            display: block;
            height: 0.25rem;
            position: relative;
            width: 100px;
            margin: 0 auto;
        }
    }

    #button {
        border-color: transparent;
        background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
        padding: 0.5rem;
        color: black;
        font-size: 1.5rem;
        border-radius: 7px;
        display: block;
        transition: all .3s ease-in-out;
        width: fit-content;
        margin: 0 auto;
        
        &:focus {
            box-shadow: none;
        }

        &:hover {
            color: black;
            text-decoration: none;
            box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
            transform: scale(1.1) translateY(-10px);
        }
    }

    .contact-us {
        margin-top: 0.5em;
    }
}

@media  (max-width: 600px) {
    .swiper-slide {
        width: 300px;
        height: 300px;
    }
}

@media  (max-width: 768px) {
    #gallery {
        width: 100%;
    }
}