.main {
    min-height: 100vh;
    margin-top: 110px;

    .loading-container {
        font-size: 20px;
        text-align: center;
    
        svg {
            font-size: 25px;
        }
    }
    
    .gallery-container {
        width: 90vw;
        margin: 0 auto;
        margin-top: 20px;

        img {
            object-fit: cover;
        }   
    }

    .go-back,
    .contact-us {
        margin-top: 15px;
        margin-bottom: 15px;

        img {
            object-fit: cover;
        }

        #button {
            border-color: transparent;
            background: linear-gradient(to bottom, rgba(246,230,180,1) 0%,rgba(237,144,23,1) 100%);
            padding: 0.5rem;
            color: black;
            font-size: 1.5rem;
            border-radius: 7px;
            display: block;
            transition: all .3s ease-in-out;
            width: fit-content;
            margin: 0 auto;
            
            &:focus {
                box-shadow: none;
            }
        
            &:hover {
                color: black;
                text-decoration: none;
                box-shadow: 0 35px 20px -20px rgba(0,0,0,0.2);
                transform: scale(1.1) translateY(-10px);
            }
        }
    }
}